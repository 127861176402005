const colorsNeutral = ["#E5ECF4", "#B2C6DC", "#819FC1", "#657F9B", "#4A5F75"]
const colorsGoodBad = ["#59C04E", "#A6C34B", "#F9C749", "#F18846", "#EB4841"]

export const getScaleColor = (scaleType, level) => {
  if (scaleType === "lowHigh") {
    return colorsNeutral[level - 1]
  } else if (scaleType === "goodBad") {
    return colorsGoodBad[level - 1]
  } else if (scaleType === "badGood") {
    return colorsGoodBad[5 - level]
  }
}

const mildCKDScales = {
  caLevel: "lowHigh",
  energyLevel: "lowHigh",
  fatLevel: "goodBad",
  feLevel: "lowHigh",
  fibreLevel: "lowHigh",
  healthLevel: "goodBad",
  kLevel: "badGood",
  naLevel: "goodBad",
  pLevel: "goodBad",
  ppRatioLevel: "lowHigh",
  proteinLevel: "lowHigh",
  purineLevel: "lowHigh",
  vitBLevel: "lowHigh",
  vitCLevel: "lowHigh",
}

const severeCKDScales = {
  caLevel: "lowHigh",
  energyLevel: "lowHigh",
  fatLevel: "goodBad",
  feLevel: "lowHigh",
  fibreLevel: "lowHigh",
  healthLevel: "goodBad",
  kLevel: "lowHigh",
  naLevel: "goodBad",
  pLevel: "goodBad",
  ppRatioLevel: "lowHigh",
  proteinLevel: "goodBad",
  purineLevel: "lowHigh",
  vitBLevel: "lowHigh",
  vitCLevel: "lowHigh",
}

const dialysisScales = {
  caLevel: "lowHigh",
  energyLevel: "lowHigh",
  fatLevel: "goodBad",
  feLevel: "lowHigh",
  fibreLevel: "lowHigh",
  healthLevel: "goodBad",
  kLevel: "goodBad",
  naLevel: "goodBad",
  pLevel: "goodBad",
  ppRatioLevel: "lowHigh",
  proteinLevel: "lowHigh",
  purineLevel: "lowHigh",
  vitBLevel: "lowHigh",
  vitCLevel: "lowHigh",
}

export const getScaleType = (nutrient, stage) => {
  if (stage === "Early Stage Kidney Disease" || stage === "(Post) Transplant") {
    return mildCKDScales[nutrient]
  } else if (stage === "Late Stage Kidney Disease") {
    return severeCKDScales[nutrient]
  } else if (stage === "Dialysis") {
    return dialysisScales[nutrient]
  }
}

export default getScaleType
