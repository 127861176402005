import React from "react"
import { Divider } from "@material-ui/core"
import { makeStyles } from "@material-ui/styles"
import NutrientDetail from "./nutrientDetail"

const useStyles = makeStyles({
  divider: {
    margin: "5px 0px",
  },
})

const DetailTable = ({ data }) => {
  const classes = useStyles()
  return (
    <>
      <NutrientDetail data={data} nutrient="healthLevel" />
      <Divider className={classes.divider} />
      <NutrientDetail data={data} nutrient="energyLevel" />
      <Divider className={classes.divider} />
      <NutrientDetail data={data} nutrient="proteinLevel" />
      <Divider className={classes.divider} />
      <NutrientDetail data={data} nutrient="fatLevel" />
      <Divider className={classes.divider} />
      <NutrientDetail data={data} nutrient="kLevel" />
      <Divider className={classes.divider} />
      <NutrientDetail data={data} nutrient="naLevel" />
      <Divider className={classes.divider} />
      <NutrientDetail data={data} nutrient="pLevel" />
      <Divider className={classes.divider} />
      <NutrientDetail data={data} nutrient="caLevel" />
      <Divider className={classes.divider} />
      <NutrientDetail data={data} nutrient="feLevel" />
      <Divider className={classes.divider} />
      <NutrientDetail data={data} nutrient="fibreLevel" />
      <Divider className={classes.divider} />
      <NutrientDetail data={data} nutrient="purineLevel" />
      <Divider className={classes.divider} />
      <NutrientDetail data={data} nutrient="vitBLevel" />
      <Divider className={classes.divider} />
      <NutrientDetail data={data} nutrient="vitCLevel" />
    </>
  )
}

export default DetailTable
