import React, { useContext } from "react"
import RenderLevel from "./renderLevel"
import getScaleType from "./nutrientScales"
import { AppContext } from "../../../../context/appContext"

const RenderNutrient = ({ nutrient, level }) => {
  const { CKDStage } = useContext(AppContext)
  return (
    <RenderLevel level={level} scaleType={getScaleType(nutrient, CKDStage)} />
  )
}

export default RenderNutrient
