import React from "react"
import nutrientsMeta from "../nutrientsMeta"
import RenderNutrient from "../renderNutrient"
import { Container, Typography } from "@material-ui/core"
import { makeStyles } from "@material-ui/styles"
import RenderDescription from "./renderDescription"

const useStyles = makeStyles({
  container: {
    display: "flex",
    alignItems: "center",
    width: "100%",
    padding: "0px",
  },
  label: {
    paddingLeft: "10px",
    flexGrow: 1,
  },
  description: {
    color: "gray",
    padding: "2px",
  },
})

const NutrientDetail = ({ data, nutrient }) => {
  const classes = useStyles()
  return (
    <>
      <Container className={classes.container}>
        <RenderNutrient nutrient={nutrient} level={data[nutrient]} />
        <Typography className={classes.label}>
          {nutrientsMeta[nutrient].label}
        </Typography>
      </Container>
      <RenderDescription
        typoProps={{ variant: "caption" }}
        typoClass={classes.description}
        nutrient={nutrient}
        level={data[nutrient]}
      />
    </>
  )
}

export default NutrientDetail
