import React, { useContext } from "react"
import { Alert, AlertTitle } from "@material-ui/lab"
import getBanMessages from "./getBanMessages"
import getWarningMessages from "./getWarningMessages"
import { makeStyles } from "@material-ui/styles"
import getInfoMessages from "./getInfoMessages"
import { AppContext } from "../../../../../../context/appContext"

const useStyles = makeStyles({
  alert: {
    margin: "10px",
  },
})

const RenderMessages = ({ item }) => {
  const classes = useStyles()
  const { CKDStage } = useContext(AppContext)

  const bans = getBanMessages(CKDStage, item)
  const warnings = getWarningMessages(CKDStage, item)
  const infos = getInfoMessages(CKDStage, item)
  let warnLevel = null
  if (bans.length > 0) {
    warnLevel = "error"
  } else if (warnings.length > 0) {
    warnLevel = "warning"
  } else if (infos.length > 0) {
    warnLevel = "info"
  }

  const warningText = [...bans, ...warnings, ...infos].join(" ")

  const getTitle = warnLevel => {
    if (warnLevel === "error") return 'Avoid this food or use like a "spice"!'
    else if (warnLevel === "warning") return "Restrict intake to small amounts!"
    else if (warnLevel === "info") return "Use in moderation!"
  }

  return (
    <>
      {warnLevel ? (
        <Alert className={classes.alert} severity={warnLevel}>
          <AlertTitle>
            <strong>{getTitle(warnLevel)}</strong>
          </AlertTitle>
          {warningText}
        </Alert>
      ) : null}
    </>
  )
}

export default RenderMessages
