const nutrientsMeta = {
  caLevel: {
    labelShort: "Ca",
    label: "Calcium Content",
  },
  energyLevel: {
    labelShort: "Calories",
    label: "Calorie Density",
  },
  fatLevel: {
    labelShort: "Fat",
    label: "Fat Content",
  },
  feLevel: {
    labelShort: "Fe",
    label: "Iron Content",
  },
  fibreLevel: {
    labelShort: "Fibre",
    label: "Fibre Content",
  },
  healthLevel: {
    labelShort: "H",
    label: "Health Score",
  },
  kLevel: {
    labelShort: "K",
    label: "Potassium Content",
  },
  naLevel: {
    labelShort: "Na",
    label: "Sodium Content",
  },
  pLevel: {
    labelShort: "P",
    label: "Phosphorus Content",
  },
  proteinLevel: {
    labelShort: "Protein",
    label: "Protein Content",
  },
  purineLevel: {
    labelShort: "Purine",
    label: "Purine Content",
  },
  vitBLevel: {
    labelShort: "B Vitamins",
    label: "Content of B Vitamins",
  },
  vitCLevel: {
    labelShort: "Vitamin C",
    label: "Vitamin C Content",
  },
}

export default nutrientsMeta
