import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { FormControl, InputLabel, Select, MenuItem } from "@material-ui/core"
import Image from "gatsby-image"
import { makeStyles } from "@material-ui/styles"

const queryImages = graphql`
  query queryFoodCategoryImages {
    allStrapiFoodCategory {
      nodes {
        category
        image {
          childImageSharp {
            fixed(width: 25, height: 25) {
              ...GatsbyImageSharpFixed
            }
          }
        }
      }
    }
  }
`

const useStyles = makeStyles({
  categoryDisplay: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  image: {
    marginRight: "10px",
  },
})

const CategorySelector = ({
  allCategories,
  selectedCategory,
  onChangeCategory,
  fullWidth,
  disabled,
}) => {
  const {
    allStrapiFoodCategory: { nodes: categoryImages },
  } = useStaticQuery(queryImages)

  const classes = useStyles()

  const getImage = category => {
    const images = categoryImages.filter(c => c.category === category)
    if (images.length === 0) return null
    return images[0].image.childImageSharp.fixed
  }

  return (
    <FormControl
      style={!fullWidth ? { minWidth: "200px" } : {}}
      disabled={disabled}
      fullWidth={fullWidth}
    >
      <InputLabel id="category">Select Category</InputLabel>
      <Select
        labelId="category"
        id="categorySelect"
        value={selectedCategory}
        onChange={event => onChangeCategory(event.target.value)}
      >
        <MenuItem value="All">All Categories</MenuItem>
        {allCategories.map(c => {
          return (
            <MenuItem key={c} value={c}>
              <div className={classes.categoryDisplay}>
                <Image fixed={getImage(c)} className={classes.image} />
                {c}
              </div>
            </MenuItem>
          )
        })}
      </Select>
    </FormControl>
  )
}

export default CategorySelector
