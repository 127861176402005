const getInfoMessages = (stage, item) => {
  let messages = []
  if (stage === "Dialysis") {
    if (item.kLevel === 3) {
      messages.push("Average Potassium Content!")
    }
    if (item.pLevel === 3) {
      messages.push("Average Phosphorus Content!")
    }
  }
  return messages
}

export default getInfoMessages
