import React, { useContext, useState} from "react"
import {
  TableContainer,
  TableBody,
  Paper,
  Table
} from "@material-ui/core"
import NutrientTableRow from "./tableRow"
import NutrientTableHeader from "./tableHeader"
import { FoodCheckContext } from "../../../../../context/foodCheckContext"
import { Waypoint } from 'react-waypoint';

const NutrientTable = ({ data, height, compact }) => {
  const {
    selectedNutrients,
    setDetailItem,
    orderBy,
    setOrderBy,
    orderAsc,
    setOrderAsc,
  } = useContext(FoodCheckContext)

  const [dataLimit, setDataLimit] = useState(30)

  const sortRows = (rows) => {
    if (orderBy) {
      return [...rows].sort((a, b) =>
        orderAsc ? a[orderBy] - b[orderBy] : b[orderBy] - a[orderBy]
      )
    } else return rows
  }

  const handleSortRequest = col => {
    if (orderBy === col) {
      setOrderAsc(!orderAsc)
    } else {
      setOrderAsc(true)
    }
    setOrderBy(col)
    setDataLimit(30)
  }

  const getMoreData = () => {
    const limit = dataLimit + 30
    setDataLimit(limit)
  }

  return (
    <Paper elevation={1}>
      <TableContainer style={{maxHeight: `${height}px`}}>
        <Table stickyHeader>
          <NutrientTableHeader
            selectedColumns={selectedNutrients}
            orderBy={orderBy}
            order={orderAsc ? "asc" : "desc"}
            requestSort={handleSortRequest}
          />
          <TableBody>
              {
                sortRows(data).slice(0, dataLimit).map((item, index) => 
                  index === dataLimit-10 ?
                    <Waypoint key={index} onEnter={() => getMoreData()}>
                      <NutrientTableRow
                        foodItem={item}
                        displayedColumns={selectedNutrients}
                        clickItem={foodItem => {
                          if (!compact) {
                            setDetailItem(foodItem)
                          }
                        }}
                      />
                    </Waypoint>
                    :
                    <NutrientTableRow
                      key={index}
                      foodItem={item}
                      displayedColumns={selectedNutrients}
                      clickItem={foodItem => {
                        if (!compact) {
                          setDetailItem(foodItem)
                        }
                      }}
                    />
                ) 
              }
          </TableBody>
        </Table>

      </TableContainer>
      
    </Paper>
  )
}

export default NutrientTable
