import React, { useContext } from "react"
import {
  Dialog,
  DialogContent,
  List,
  ListItem,
  ListItemText,
  Typography,
  ListItemIcon,
  Toolbar,
  IconButton,
  Container,
} from "@material-ui/core"
import { ScreenContext } from "../../../../context/screenContext";
import nutrientsMeta from "./nutrientsMeta";
import CloseIcon from "@material-ui/icons/Close"
import RenderLevel from "./renderLevel";
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles({
    toolbar: {
        justifyContent: "space-between"
    },
    container: {
        width: "300px",
        marginBottom: "15px",
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-evenly",
    },
  })

const NutrientInfoDialog = ({ open, setOpen }) => {
  const screen = useContext(ScreenContext)

  const classes = useStyles()

  const allNutrients = Object.keys(nutrientsMeta)

  return (
    <Dialog
      fullScreen={screen.mobile ? true : false}
      open={open}
      onClose={() => setOpen(false)}
    >
        <Toolbar className={classes.toolbar}>
            <Typography variant={screen.mobile? "h6": "h5"}>Nutrients - Info</Typography>
            <IconButton onClick={() => setOpen(false)}><CloseIcon/></IconButton>
        </Toolbar>
      
        <DialogContent>
            <Typography paragraph>All Nutrients are displayed with a discrete scale (between 1 und 5).</Typography>
            <Typography paragraph>In case low resp. high values are always associated with good or bad we use a biased scale (from green to red).</Typography>
            <Container className={classes.container}>
                <Typography variant="caption">Good</Typography>
                {[1, 2, 3, 4, 5].map(level => {
                return (
                    <RenderLevel
                    level={level}
                    key={level}
                    scaleType="goodBad"
                    fontSize="20"
                    />
                )
                })}
                <Typography variant="caption">Bad</Typography>
            </Container>
            <Typography paragraph>In other cases we use a neutral scale (from light blue to dark blue).</Typography>
            <Container className={classes.container}>
                <Typography variant="caption">Low</Typography>
                {[1, 2, 3, 4, 5].map(level => {
                return (
                    <RenderLevel
                    level={level}
                    key={level}
                    scaleType="lowHigh"
                    fontSize="20"
                    />
                )
                })}
            <Typography variant="caption">High</Typography>
      </Container>

            <List>
                {allNutrients.map((col, index) => {
                return (
                    <ListItem
                    alignItems="center"
                    key={index}
                    >
                    <ListItemIcon style={{width: "100px"}}>
                        <Typography variant="body1">{nutrientsMeta[col].labelShort}</Typography>
                    </ListItemIcon>
                    <ListItemText primary={nutrientsMeta[col].label} />
                    
                    </ListItem>
                )
                })}
            </List>
        </DialogContent>
    </Dialog>
  )
}

export default NutrientInfoDialog
