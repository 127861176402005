const getWarningMessages = (stage, item) => {
  let messages = []
  if (stage === "Dialysis") {
    if (item.kLevel === 4) {
      messages.push("High in Potassium!")
    }
    if (item.pLevel === 4) {
      messages.push("High in Phosphorus!")
    }
  }
  return messages
}

export default getWarningMessages
