import React from "react"
import {
  TableRow,
  TableCell,
  TableHead,
  Tooltip,
  Typography,
  TableSortLabel,
} from "@material-ui/core"
import nutrientsMeta from "../nutrientsMeta"
import { makeStyles } from "@material-ui/styles"

const useStyles = makeStyles({
  cell: {
    userSelect: "none",
  }
})

const NutrientTableHeader = ({
  selectedColumns,
  orderBy,
  order,
  requestSort,
}) => {
  const classes = useStyles()

  const ColumnTooltip = ({ children, col }) => {
    return (
      <Tooltip
        title={
          <Typography variant="caption">
            {nutrientsMeta[col].label}
          </Typography>
        }
        arrow
        leaveDelay={150}
        interactive
      >
        {children}
      </Tooltip>
    )
  }

  return (
    <TableHead>
      <TableRow>
        <TableCell>
          <strong>Food Item</strong>
        </TableCell>
        {selectedColumns.map(col => (
          <TableCell key={col} className={classes.cell}>
            <ColumnTooltip col={col}>
              <TableSortLabel
                active={orderBy === col}
                direction={orderBy === col ? order : "asc"}
                onClick={() => requestSort(col)}
              >
                <strong>{nutrientsMeta[col].labelShort}</strong>
              </TableSortLabel>
            </ColumnTooltip>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  )
}

export default NutrientTableHeader
