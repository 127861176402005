import React, { useContext } from "react"
import { Typography } from "@material-ui/core"
import { AppContext } from "../../../../../context/appContext"

const getDescription = (nutrient, level, stage) => {
  switch (nutrient) {
    case "healthLevel":
      if (level === 1)
        return "Vegetables & Fruits considered the most healthy foods!"
      if (level === 2) return "Whole foods are considered healthy."
      if (level === 3) return "Still healthy if eaten in moderation."
      if (level === 4) return "Less healthy food, restrict to small amounts."
      if (level === 5)
        return "Considered unhealthy, try to avoid or use in very small amounts."
      break
    case "energyLevel":
      if (level === 1 || level === 2)
        return "Low calorie food. Good if you are overweight!"
      if (level === 3) return "Food with average calorie density."
      if (level === 4 || level === 5)
        return "High calorie food. Restrict if overweight."
      break
    case "proteinLevel":
      if (stage === "Dialysis") {
        if (level === 1 || level === 2)
          return "Does not help much to restore protein lost during dialysis."
        if (level === 3) return "Average source of protein."
        if (level === 4 || level === 5)
          return "Protein dense food. Provides Amino Acids, but also produces additional metabolic waste products."
      } else {
        if (level === 1)
          return "Very low protein food. Doesn't stress your kidneys!"
        if (level === 2)
          return "Low protein food. Good choice for weak kindeys."
        if (level === 3) return "Average protein content. Eat in moderation."
        if (level === 4 || level === 5)
          return "Food with higher protein puts stress on your kidneys (true especially for animal proteins)."
      }
      break
    case "fatLevel":
      if (level === 1 || level === 2)
        return "Food low in fat. Good for your arteries!"
      if (level === 3) return "Average fat content. Eat in moderation."
      if (level === 4 || level === 5)
        return "Higher fat food. Watch out for your cholesterol levels!"
      break
    case "fibreLevel":
      if (level === 1 || level === 2) return "Food low in fibre!"
      if (level === 3) return "Source of dietary fibre."
      if (level === 4) return "Good Source of dietary fibre."
      if (level === 5) return "Excellent Source of dietary fibre."
      break
    case "kLevel":
      if (stage === "Dialysis") {
        if (level === 1)
          return "Very low in potassium. Safe to eat but still check your lab values."
        if (level === 2)
          return "Low potassium food. Ok to eat, but check your lab values."
        if (level === 3) return "Medium potassium content. Eat in moderation."
        if (level === 4)
          return "Relatively high in potassium. Limit to smaller amounts."
        if (level === 5)
          return "Very high in potassium. Avoid or use as like a 'spice'."
      } else if (stage === "Late Stage Kidney Disease") {
        if (level === 1 || level === 2)
          return "Relatively low in potassium. Safe to eat."
        if (level === 3)
          return "Medium potassium food. Safe, but check lab values on a regular basis."
        if (level === 4 || level === 5)
          return "Higher in potassium. Ok to eat, unless your kidney function is very low. Talk with your doctor!"
      } else {
        if (level === 1 || level === 2) return "Low potassium food."
        if (level === 3) return "Medium potassium food."

        if (level === 4 || level === 5)
          return "Higher potassium content. In earlier stages of CKD its healthy to eat higher potassium and lower sodium food."
      }
      break
    case "pLevel":
      if (stage === "Dialysis" || stage === "Late Stage Kidney Disease") {
        if (level === 1 || level === 2) return "Low in Phosphorus. Safe to eat."
        if (level === 3) return "Medium Content. Eat in limited amounts."
        if (level === 4)
          return "Higher in Phosphorus. Try to restrict to small amounts."
        if (level === 5)
          return "High Phosophorus Food. Try to avoid or use as like a 'spice'."
      } else {
        if (level < 3) return "Low in Phosphorus. Safe to eat."
        if (level === 3) return "Medium Content. Still safe to eat."
        if (level === 4)
          return "Higher in Phosphorus. Limit intake. Check your lab values."
        if (level === 5)
          return "High Phosophorus Food. Avoid or limit to small amounts. Check your lab values."
      }
      break
    case "naLevel":
      if (level === 1 || level === 2)
        return "Low in Sodium. That's good for your blood pressure!"
      if (level === 3)
        return "Medium sodium content. Try to find low sodium alternatives."
      if (level === 4 || level === 5)
        return "Higher in Sodium. Look for low sodium alternatives."
      break
    case "caLevel":
      if (level === 1 || level === 2) return "Lower calcium food."
      if (level === 3) return "Source of dietary calcium."
      if (level === 4) return "Good Source of dietary calcium."
      if (level === 5) return "Excellent Source of dietary calcium."
      break
    case "feLevel":
      if (level === 1 || level === 2) return "Lower iron content."
      if (level === 3) return "Supplies some iron."
      if (level === 4) return "Good Source of iron."
      if (level === 5) return "Excellent Source of iron."
      break
    case "purineLevel":
      if (level === 1 || level === 2)
        return "Lower purine content. That's good to minimize uric acid productions."
      if (level === 3) return "Medium purine content. "
      if (level === 4 || level === 5)
        return "Higher purine content. Watch out for 'uric acid' in your lab."
      break
    case "vitBLevel":
      if (level === 1 || level === 2) return "Lower content of B Vitamins."
      if (level === 3) return "Source of B Vitamins."
      if (level === 4) return "Good Source of B Vitamins"
      if (level === 5) return "Excellent Source of B Vitamins"
      break
    case "vitCLevel":
      if (level === 1 || level === 2) return "Relatively low in Vitamin C."
      if (level === 3) return "Source of Vitamin C."
      if (level === 4) return "Good Source of Vitamin C"
      if (level === 5) return "Excellent Source of Vitamin C"
      break
    default:
      break
  }
}

const RenderDescription = ({ nutrient, level, typoClass, typoProps }) => {
  const { CKDStage } = useContext(AppContext)
  return (
    <Typography {...typoProps} className={typoClass}>
      {getDescription(nutrient, level, CKDStage)}
    </Typography>
  )
}

export default RenderDescription
