import React from "react"
import { Typography, Paper, Toolbar, IconButton, Grid } from "@material-ui/core"
import CloseIcon from "@material-ui/icons/Close"
import { makeStyles } from "@material-ui/styles"
import RenderMessages from "./messages/renderMessages"
import DetailTable from "./detailTable"
import Image from "gatsby-image"

const useStyles = makeStyles({
  paper: {
    padding: "5px",
  },
  toolbar: {
    padding: "0px 0px 0px 10px",
  },
  caption: {
    flexGrow: 1,
  },
  container: {
    maxWidth: "800px",
    padding: "15px",
  },
  nutrientList: {
    display: "flex",
    flexDirection: "column",
  },
  foodImage: {
    borderRadius: "10px",
  },
})

const DetailView = ({ data, photo, closeDetail }) => {
  const classes = useStyles()
  return (
    <Paper className={classes.paper}>
      <Toolbar variant="dense" className={classes.toolbar}>
        <Typography variant="h5" className={classes.caption}>
          {data.foodEN}
        </Typography>
        <IconButton
          aria-label="close"
          disableRipple
          className={classes.closeButton}
          onClick={closeDetail}
        >
          <CloseIcon />
        </IconButton>
      </Toolbar>
      <RenderMessages item={data} />
      <Grid container spacing={4} className={classes.container}>
        <Grid item xs={5}>
          {photo ? <Image fluid={photo} className={classes.foodImage} /> : null}
        </Grid>
        <Grid item xs={7} className={classes.nutrientList}>
          <DetailTable data={data} />
        </Grid>
      </Grid>
    </Paper>
  )
}

export default DetailView
