import React, {useState} from "react"
import { Paper, Container, Button } from "@material-ui/core"
import { makeStyles } from "@material-ui/styles"
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import SettingsOutlinedIcon from '@material-ui/icons/SettingsOutlined';
import NutrientInfoDialog from "../nutrientInfoDialog";
import NutrientSelectorDialog from "../nutrientSelectorDialog";

const useStyles = makeStyles({
  container: {
    padding: "10px",
    marginBottom: "5px",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  
})

const NutrientTableTools = () => {
  const classes = useStyles()

  const [infoOpen, setInfoOpen] = useState(false)
  const [selectorOpen, setSelectorOpen] = useState(false)

  return (
    <>
    <Paper elevation={1}>
      <Container className={classes.container}>
        <Button 
            variant="outlined" 
            size="small" 
            startIcon={<InfoOutlinedIcon/>}
            onClick={() => setInfoOpen(true)}
        >Info</Button>
        <Button 
            variant="outlined" 
            size="small" 
            startIcon={<SettingsOutlinedIcon/>}
            onClick={() => setSelectorOpen(true)}
        >Add/Remove Columns</Button>
      </Container>
    </Paper>

    <NutrientInfoDialog open={infoOpen} setOpen={setInfoOpen}/>
    <NutrientSelectorDialog open={selectorOpen} setOpen={setSelectorOpen} />
    </>
  )
}

export default NutrientTableTools