const getBanMessages = (stage, item) => {
  let messages = []
  if (stage === "Dialysis") {
    if (item.kLevel === 5) {
      messages.push("Very High in Potassium!")
    }
    if (item.pLevel === 5) {
      messages.push("Very High in Phosphorus!")
    }
  }
  return messages
}

export default getBanMessages
