import React from "react"
import { SvgIcon } from "@material-ui/core"
import { getScaleColor } from "./nutrientScales"

function SquareIcon(props) {
  return (
    <SvgIcon {...props}>
      <path d="M19,3H5C3.89,3 3,3.89 3,5V19A2,2 0 0,0 5,21H19A2,2 0 0,0 21,19V5C21,3.89 20.1,3 19,3Z" />
    </SvgIcon>
  )
}

const RenderLevel = ({ level, scaleType, fontSize }) => {
  const color = getScaleColor(scaleType, level)

  return (
    <SquareIcon
      style={{
        color: `${color}`,
        fontSize: fontSize,
      }}
    />
  )
}

export default RenderLevel
