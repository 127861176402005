import React, { useContext } from "react"
import { useStaticQuery, graphql } from "gatsby"
import {
  Toolbar,
  Paper,
  TextField,
  InputAdornment,
  Container,
  Typography,
  Button, 
  ButtonGroup
} from "@material-ui/core"
import { makeStyles } from "@material-ui/styles"
import SearchIcon from "@material-ui/icons/Search"
import NutrientTable from "./nutrientTable/nutrientTable"
import { FoodCheckContext } from "../../../../context/foodCheckContext"
import CategorySelector from "./categorySelector"
import DetailView from "./nutrientTable/detailView"
import { AppContext } from "../../../../context/appContext"
import CKDStageDialog from "../../../ckdStageDialog"
import { ScreenContext } from "../../../../context/screenContext";
import ReorderIcon from '@material-ui/icons/Reorder';
import GridOnIcon from '@material-ui/icons/GridOn';
import NutrientTableTools from "./nutrientTable/nutrientTableTools";

const queryFoodCheck = graphql`
  query queryFoodCheck {
    allStrapiNutrient {
      nodes {
        categoryEN
        foodEN
        vegetarian
        vegan
        caLevel
        energyLevel
        fatLevel
        feLevel
        fibreLevel
        healthLevel
        kLevel
        naLevel
        pLevel
        ppRatioLevel
        proteinLevel
        purineLevel
        vitBLevel
        vitCLevel
      }
    }
    allStrapiNutrientsPhotos {
      nodes {
        foodEN
        image {
          childImageSharp {
            fluid(maxWidth: 300, quality: 25) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`

const useStyles = makeStyles(theme => ({
  header: {
    backgroundColor: theme.palette.primary.main,
    marginBottom: "10px",
  },
  caption: {
    color: "white",
    flexGrow: 1,
    fontSize: "1.1em",
  },
  matrixSwitch: {
    color: "white",
    borderColor: "white",
    padding: "10px"
  },
  toolbar: {
    padding: "15px",
    marginBottom: "3px",
  },
  searchfield: {
    marginLeft: "15px",
    flexGrow: 1,
  },
  noMatches: {
    marginTop: "50px",
  },
}))

const FoodCheck = ({compact, setView}) => {
  
  const { CKDStage } = useContext(AppContext)
  const {
    selectedCategory,
    setSelectedCategory,
    searchText,
    setSearchText,
    detailItem,
    setDetailItem
  } = useContext(FoodCheckContext)

  const classes = useStyles()
  const {
    allStrapiNutrient: { nodes: foodItems },
    allStrapiNutrientsPhotos: { nodes: foodPhotos },
  } = useStaticQuery(queryFoodCheck)

  const foodCategories = [...new Set(foodItems.map(item => item.categoryEN))]

  const foodFilter = item => {
    const categoryFilter =
      selectedCategory === "All" ? true : item.categoryEN === selectedCategory
    const searchFilter = item.foodEN
      .toLowerCase()
      .includes(searchText.toLowerCase())
    return categoryFilter & searchFilter
  }

  const getPhoto = item => {
    const photoQuery = foodPhotos.filter(
      photoItem => photoItem.foodEN === item.foodEN
    )
    if (photoQuery.length === 0) return null
    const photoItem = photoQuery[0]
    if (!photoItem.image) return null
    return photoItem.image.childImageSharp.fluid
  }

  const getfilteredFoods = () => foodItems.filter(foodFilter)

  const displayFoodTable = (items, compact) => {
    if (!CKDStage) {
      return null
    } else if (items.length === 1 && !compact) {
      getPhoto(items[0])
      return (
        <>
          <DetailView
            data={items[0]}
            photo={getPhoto(items[0])}
            closeDetail={() => setSearchText("")}
          />
        </>
      )
    } else if (detailItem && !compact) {
      return (
        <>
          <DetailView
            data={detailItem}
            photo={getPhoto(detailItem)}
            closeDetail={() => setDetailItem(null)}
          />
        </>
      )
    } else if (items.length > 1) {
      return (
        <>
          {!compact ? <NutrientTableTools/> : null}
          <NutrientTable data={items} height={!compact ? 700: 350} compact={compact}/>
        </>
      )
    } else {
      return (
        <Container align="center" className={classes.noMatches}>
          <Typography variant="caption">No matching items</Typography>
        </Container>
      )
    }
  }

  return (
    <div>
      {!compact ?
      <Toolbar variant="dense" className={classes.header}>
        <Typography variant="overline" align="left" className={classes.caption}>
          Nutrients
        </Typography>
        <ButtonGroup size="small" variant="text">
          <Button startIcon={<ReorderIcon/>} className={classes.matrixSwitch} disabled>List</Button>
          <Button startIcon={<GridOnIcon/>} className={classes.matrixSwitch} onClick={() => setView("matrix")}>Matrix</Button>
        </ButtonGroup>
      </Toolbar>
      : null }

      {!CKDStage ? <CKDStageDialog open={true} setOpen={() => null} /> : null}

      {CKDStage && !compact ? (
        <Paper elevation={1} component={Toolbar} className={classes.toolbar}>
          <CategorySelector
            allCategories={foodCategories}
            selectedCategory={selectedCategory}
            onChangeCategory={cat => {
              setSelectedCategory(cat)
            }}
          />

          <TextField
            className={classes.searchfield}
            id="searchField"
            label="Find"
            placeholder="Enter food item..."
            value={searchText}
            onChange={event => {
              setSearchText(event.target.value)
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
          />
        </Paper>
      ) : null}

      {displayFoodTable(getfilteredFoods(), compact)}
    </div>
  )
}

export default FoodCheck
