import React from "react"
import { TableRow, TableCell } from "@material-ui/core"
import RenderNutrient from "../renderNutrient"

const NutrientTableRow = ({ foodItem, displayedColumns, clickItem, innerRef }) => {
  return (
    <TableRow hover ref={innerRef} onClick={() => clickItem(foodItem)}>
      <TableCell>{foodItem["foodEN"]}</TableCell>
      {displayedColumns.map((col, index) => {
        return (
          <TableCell key={index}>
            <RenderNutrient nutrient={col} level={foodItem[col]} />
          </TableCell>
        )
      })}
    </TableRow>
  )
}

export default NutrientTableRow
