import React, { useContext, useState } from "react"
import {
  Dialog,
  DialogContent,
  DialogActions,
  Typography,
  Toolbar,
  IconButton,
  Button,
  FormControl,
  FormGroup,
  FormControlLabel,
  Checkbox
} from "@material-ui/core"
import { ScreenContext } from "../../../../context/screenContext";
import nutrientsMeta from "./nutrientsMeta";
import CloseIcon from "@material-ui/icons/Close"
import { makeStyles } from "@material-ui/styles";
import { FoodCheckContext } from "../../../../context/foodCheckContext";

const useStyles = makeStyles({
    toolbar: {
        justifyContent: "space-between"
    }
  })

const NutrientSelectorDialog = ({ open, setOpen }) => {
    const screen = useContext(ScreenContext)
    const {
        selectedNutrients,
        setSelectedNutrients
    } = useContext(FoodCheckContext)

    const [selected, setSelected] = useState(selectedNutrients)

    const classes = useStyles()

    const allNutrients = Object.keys(nutrientsMeta)

    const handleChange = (event) => {
        const col = event.target.name
        if (!selected.includes(col) && event.target.checked) {
            setSelected([...selected, col])
        }
        else if (selected.includes(col) && !event.target.checked) {
            const newSelection = selected.filter(c => c !== col)
            setSelected(newSelection)
        }
    }

    const onAccept = () => {
        setSelectedNutrients(selected)
        setOpen(false)
    }

    const isChecked = (col) => {
        return selected.includes(col)
    }

  return (
    <Dialog
      fullScreen={screen.mobile ? true : false}
      open={open}
      onEnter={() => setSelected(selectedNutrients)}
      onClose={() => setOpen(false)}
    >
        <Toolbar className={classes.toolbar}>
            <Typography variant={screen.mobile? "h6": "h5"}>Nutrients - Add/Remove</Typography>
            <IconButton onClick={() => setOpen(false)}><CloseIcon/></IconButton>
        </Toolbar>
      
        <DialogContent>
            <FormControl component="fieldset">
                <FormGroup>
                    {
                        allNutrients.map((col, index) => {
                            return (
                                <FormControlLabel
                                    control={<Checkbox checked={isChecked(col)}
                                            color="primary"
                                            onChange={handleChange} 
                                            name={col} />}
                                    label={`${nutrientsMeta[col].label} (${nutrientsMeta[col].labelShort})`}
                                />
                            )
                        })
                    }
                </FormGroup>
            </FormControl>
        </DialogContent>
        <DialogActions>
            <Button onClick={() => onAccept()} color="primary" variant="contained">
                Apply
            </Button>
      </DialogActions>
    </Dialog>
  )
}

export default NutrientSelectorDialog
